<template>
  <v-container
    id="calendar"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[
            { text: 'Ваш центр', to: '/' },
            { text: 'Расписание преподавателей' }
          ]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="mx-auto"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>
        <v-card>
          <v-sheet
            flat
            class="pa-2"
          >
            <v-toolbar flat>
              <v-btn
                class="ma-1"
                color="secondary"
                rounded
                @click="newEvent()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Добавить
              </v-btn>
              <v-spacer />
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-toolbar-title>
                {{ title }}
              </v-toolbar-title>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-spacer />
              <v-autocomplete
                v-model="filterTeacher_id"
                label="Преподаватель"
                item-value="id"
                item-text="name"
                :items="teachers"
                clearable
                style="max-width: 300px"
                class="mt-2 mr-8"
              />
              <v-menu
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>День</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Неделя</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Месяц</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-calendar
              ref="calendar"
              v-model="focus"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              locale="ru-RU"
              :type="type"
              :events="events"
              :event-color="getEventColor"
              :event-more="false"
              first-time="08:00"
              :event-name="e => e.input.name"
              :interval-format="e => e.time"
              off-interval-count="6"
              off-interval-minutes="240"
              @click:event="editEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="getEvents"
            />
          </v-sheet>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import teachersApi from '../services/TeachersApi'
  import clientsApi from '../services/ClientsApi'
  import timeplanApi from '../services/TimeplanApi'

  export default {
    filters: {
      formatDate: function (date) {
        return moment(date).format('YYYY-MM-DD')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('YYYY-MM-DD HH:mm')
      },
    },
    props: ['teacherId'],
    data: function () {
      return {
        confirmDialog: false,
        event_def: {
          event_date: null,
          begintime: null,
          endtime: null,
          teacher_id: null,
          occupied: null,
          student_id: null,
          comment: null,
          timeplanresult_id: null,
          result_comment: null,
          notify: null,
          periodic: null,
          interval: null,
          interval_type: null,
          period_end: null,
        },
        event: {},
        event_datemenu: false,
        period_endmenu: false,

        focus: null,
        title: '',
        type: 'month',
        typeToLabel: {
          month: 'Месяц',
          week: 'Неделя',
          day: 'День',
        },
        events: [],
        teachers: [],
        students: [],
        timeplanresults: [],
        interval_types: [
          { id: 1, short: 'y', key: 'years', name: 'Год' },
          { id: 2, short: 'Q', key: 'quarters', name: 'Квартал' },
          { id: 3, short: 'M', key: 'months', name: 'Месяц' },
          { id: 4, short: 'w', key: 'weeks', name: 'Неделя' },
          { id: 5, short: 'd', key: 'days', name: 'День' },
        ],
        filterTeacher_id: null,
        error: null,
        loading: false,

        eventDialog: false,

        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter((field) => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },
    computed: {
      computedDate1FormattedMomentjs () {
        return this.date1 ? moment(this.date1).format('DD.MM.YYYY') : ''
      },
    },
    watch: {
      filterTeacher_id: function (val) {
        this.loadEvents()
      },
    },
    mounted () {
      document.title = 'Учебная сетка | ' + process.env.VUE_APP_NAME
      moment.locale('ru')

      teachersApi
        .fetchActiveTeachers()
        .then((response) => {
          this.teachers = response
        })
        .catch((error) => {
          this.error = this.pretty(error.response.data)
        })

      clientsApi
        .fetchClientsLookup()
        .then((response) => {
          this.students = response
        })
        .catch((error) => {
          this.error = this.pretty(error.response.data)
        })

      this.$refs.calendar.checkChange()
    },
    methods: {
      getEvents ({ start, end }) {
        return this.loadEvents(start.date, end.date)
      },

      loadEvents (bgdate, enddate) {
        if (!bgdate) bgdate = this.$refs.calendar.lastStart.date
        if (!enddate) enddate = this.$refs.calendar.lastEnd.date // ? this.$refs.calendar.end : moment(bgdate).add(1, this.$refs.calendar.type).format('YYYY-MM-DD')
        if(this.type == 'month') {
            this.title = moment(this.$refs.calendar.lastStart.date)
              .locale('ru-ru')
              .format('MMMM YYYY')
        } else if(this.type == 'day') {
            this.title = moment(this.$refs.calendar.lastStart.date)
              .locale('ru-ru')
              .format('DD MMMM YYYY')
        } else {
            this.title = moment(this.$refs.calendar.lastStart.date)
              .locale('ru-ru')
              .format('DD - ')
            + moment(this.$refs.calendar.lastEnd.date)
              .locale('ru-ru')
              .format('DD MMMM YYYY')
        }

        timeplanApi
          .fetchEventsBy(bgdate, enddate, this.filterTeacher_id)
          .then((response) => {
            let events = this.spreadPereodic(response, enddate)

            events = events.map((e) => ({
              ...e,
              ...{
              name:
                e.begintime.substring(0, 5) +
                ' ' +
                (e.periodic == 1 ? '🕗' : (e.periodic == 2 ? (e.id ? '⟳' :  '↻') : '')) +
                ' ' +
                e.comment,
              start: e.event_date + ' ' + e.begintime,
              color: e.occupied == 1 ? 'orange' : 'grey',
            },
            }))

            this.events = events
          })
          .catch((error) => {
            alert(error)
          })
      },

      newEvent () {
        this.$router.push({
          name: 'Событие',
          params: { ...this.event_def },
        })
      },

      editEvent (item) {
        if (item.event.id) {
          this.$router.push({
            name: 'Событие',
            query: { id: item.event.id },
          })
        } else {
          this.$router.push({ name: 'Событие', params: item.event })
        }
      },

      viewDay (e) {
        this.type = 'day'
      },

      getEventColor (event) {
        return event.color
      },

      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      spreadPereodic (events, enddate) {
        let virtualEvents = []
        for (let i = 0; i < events.length; i++) {
          if (events[i].periodic == 1) {
            virtualEvents.push(...this.spread(events[i], events, enddate))
          }
        }
        return events.concat(virtualEvents)
      },
      spread (event, allEvents, enddate) {
        let events = []
        const start = moment(event.event_date)
        const end = moment(event.period_end ? event.period_end : enddate)
        const key = this.getBy(
          'id',
          event.interval_type,
          this.interval_types
        ).key
        if (!key) return events
        for (let dt = start.clone(); dt <= end; dt.add(event.interval, key)) {
          let e = { ...event } // clone
          e.event_date = dt.format('YYYY-MM-DD')
          e.periodic = 2
          e.timeplanresult_id = null
          e.result_comment = null
          e.id = null
          if (!this.eventExists(e, allEvents)) {
            events.push(e)
          }
        }
        return events
      },
      eventExists (e, allEvents) {
        for (let index = 0; index < allEvents.length; index++) {
          if (
            allEvents[index]['event_date'] === e.event_date &&
            allEvents[index]['begintime'] === e.begintime &&
            allEvents[index]['teacher_id'] === e.teacher_id &&
            allEvents[index]['periodic'] > 0
          ) { return true }
        }

        return false
      },
      getBy (propname, value, array) {
        for (let index = 0; index < array.length; index++) {
          if (array[index][propname] === value) return array[index]
        }

        return {}
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
    },
  }
</script>
